import React from "react";

const MapComponent = (props) => {
  return (
    <div style={{ width: "100%", height: props.h ? props.h : "450px" }}>
      <iframe
        title="Google Map"
        srcDoc={`
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.1786906597904!2d-117.6916805!3d33.626608499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dce935a1b93131%3A0x87da44bc89cae463!2sAA%20Smog%20Test%20Only!5e0!3m2!1sen!2s!4v1704053296613!5m2!1sen!2s" width="100%" height="420" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        `}
        frameBorder="0"
        width="100%"
        height="100%"></iframe>
    </div>
  );
};

export default MapComponent;
