import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
  TableCaption,
  Flex,
  Button,
} from "@chakra-ui/react";
import React from "react";

export default function Dealership() {
  const dealerships = [
    {
      name: "Franks Subaru",
      phone: "948-847-3500",
      contact: "Sebastian Service Manager",
      address: "23663 Rockfield Blvd Lake forest CA 92630",
    },
  ];

  return (
    <div>
      <Box p={30}>
        <Heading fontSize={{ base: "xl", md: "xx-large" }} textAlign="center">
          Dealerships
        </Heading>
        <Text maxW={800} mx="auto" mt={30} fontSize="large">
          Explore the power of collaboration within our network of automotive
          partnerships, revealing the exceptional synergy that sets us apart. As
          your go-to smog shop, we proudly team up with other trusted
          dealerships, ensuring a comprehensive experience tailored to your
          every automotive need
        </Text>
      </Box>
      <Flex
        direction="column"
        align="center"
        justify="center"
        m={5}
        p={{ base: 0, md: 5 }}
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="lg"
        color="black"
        background="gray.200"
        overflowX={{ base: "auto", md: "hidden" }}>
        <TableContainer style={{ overflowY: "auto", maxHeight: "70vh" }}>
          <Table variant="" size="lg" align="center">
            <Thead>
              <Tr>
                <Th>S.No</Th>
                <Th>Name</Th>
                <Th>Phone</Th>
                <Th>Contact</Th>
                <Th>Address</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dealerships.map((dealer, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{dealer.name}</Td>
                  <Td>{dealer.phone}</Td>
                  <Td>{dealer.contact}</Td>
                  <Td>{dealer.address}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </div>
  );
}
