import React from "react";

import CustomBanner from "../../components/CustomBanner";
import HorizontalTab from "../../components/HorizantalTab";
import {
  Image,
  Text,
  Box,
  Heading,
  Button,
  useTheme,
  Flex,
} from "@chakra-ui/react";
import ContentImage from "../../components/ContentImage";
import InfoBox from "../../components/InfoBox";
import {
  Banner,
  BannerH,
  Fotios,
  Hallway,
  Holiday,
  Kaique,
  Logo,
  M2,
  M3,
  M4,
  M5,
  M6,
  Money,
  Tolga,
  Vedanti,
  m1,
  shopping,
} from "../../assets/images";
import { FaLongArrowAltRight } from "react-icons/fa";
import ContactForm from "../../components/ContactForm";
import { motion } from "framer-motion";

const Home = () => {
  const theme = useTheme();
  const bgColor = theme.colors.brand[800];

  const bannerStyle = {
    backgroundImage: `url(${M2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: 2,
  };

  return (
    <>
      <div style={{ position: "relative", marginBottom: "110px" }}>
        <CustomBanner
          firstText="Rated #1 Smog Check Gardena CA"
          title="Neighbor Hood Smog Shop"
          description="Searching for smog check Gardena? Neighbor Hood Smog Shop is the #1 Leader of Smog Checks (we’re your local smog certification center) CA. Here at Neighbor Hood we gladly service cars that were manufactured in 1999 or later, and we will gladly service motorhomes, boats, atv, motorcycle, hybrids, and diesel trucks. These are services that surrounding locations prefer not to do and they quickly turn customers away. Not us! We wont turn you away. Sure some of these take more time and significantly more testing, but we’re committed to take care of you. And we try our very best to get you back on the road as quickly as possible.."
          buttonText1="See Offers"
          buttonText2="Shop Now"
          imageUrl={BannerH}
          showButtons="multiple"
        />
        <HorizontalTab
          position={"absolute"}
          leftContent={
            <Text fontWeight={"bold"} fontSize={20}>
              Find Out Why We're A Top-Rated <br /> Smog Check & Registration
              Shop
            </Text>
          }
          rightContent={
            <Text fontSize={20}>
              SE HABLA ESPAÑOL
              <br />
              <span style={{ fontWeight: "bold", fontSize: "32" }}>
                (909) 499-9590
              </span>
            </Text>
          }
        />
      </div>
      <Flex
        direction={{ base: "column", md: "row" }}
        align={{ base: "center", md: "flex-start" }}
        justify="center"
        color="white"
        p={{ base: 0, md: 8 }}>
        <Box
          maxW={{ base: "100%", md: "50%" }}
          mb={{ base: 8, md: 0 }}
          w="100%"
          display={{ base: "none", md: "block" }}>
          <Flex gap={3} align={"center"}>
            <Flex gap={3} direction={"column"} w={"50%"} h={"100%"}>
              <Image src={M5} alt="Image" borderRadius="md" h="250px" />
              <Image src={M2} alt="Image" borderRadius="md" h="350px" />
            </Flex>
            <Flex gap={3} direction={"column"} w={"50%"} h={"100%"}>
              <Image src={M3} alt="Image" borderRadius="md" h="350px" />
              <Image src={M4} alt="Image" borderRadius="md" h="250px" />
            </Flex>
          </Flex>
        </Box>
        <Box
          w="100%"
          mb={{ base: 8, md: 0 }}
          display={{ base: "block", md: "none" }}
          mt={{ base: -100, md: 0 }}>
          <Image src={M6} alt="Image" borderRadius="md" />
        </Box>
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ duration: 10 }}
          style={{ maxWidth: "100%", width: "100%" }}>
          <Box
            mb={{ base: 8, md: 0 }}
            height={"600px"}
            style={bannerStyle}
            textAlign={{ base: "center", md: "left" }}
            justifyContent={"center"}
            py={{ base: 5, md: 10 }}
            px={{ base: 2, md: 10 }}
            mt={{ base: 0, md: -20 }}
            ml={{ base: 0, md: -20 }}
            borderRadius="md">
            <Flex
              direction={"column"}
              align={{ base: "center", md: "flex-start" }}
              justifyContent="center"
              width={"100%"}>
              <Box>
                <Image src={Logo} alt={""} h={59} w={150} mb={5} />
              </Box>
              <Text fontSize={{ base: "md", md: "lg" }} mb="6">
                ABOUT US
              </Text>
              <Heading as="h1" fontSize={{ base: "2xl", md: "4xl" }} mb="4">
                We’re Your Local Smog Certification Center
              </Heading>
              <Text fontSize={{ base: "md", md: "lg" }} mb="6">
                Discover a nearby smog certification service with our
                cutting-edge system. We seamlessly link to the California state
                DMV database, electronically updating them on your results and
                instantly confirming your certification approval from our shop.
              </Text>
              <Button
                onClick={() => (window.location.href = "/contact")}
                w={60}
                p={6}
                bg={bgColor}
                colorScheme={"while"}>
                <Text mr={2}>Contact US</Text>
                <FaLongArrowAltRight />
              </Button>
            </Flex>
          </Box>
        </motion.div>
      </Flex>
      {/* <ContentImage title="Coupon Download"
        description="Ready for service? Click the button below to download our coupon and show it to one of our team members before you pay for your service!        "
        buttonText="Download Now!"
        imageUrl={M5}
        imageOnLeft={false}
      /> */}
      <CustomBanner imageUrl={M2}>
        <Box
          bg={"white"}
          color={"black"}
          maxW={{ base: "100%", md: "70%" }}
          w="100%"
          p={8}
          mt="8">
          <Text>Neighbor Hood Smog Shop</Text>
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "4xl" }}
            mb="4"
            color={bgColor}>
            We Operate At The Highest Standards
          </Heading>
          <Text fontSize={{ base: "md", md: "lg" }} mb="6">
            Our standards surpass the state's minimum requirements. Give us the
            chance to serve you and your vehicle, and we'll go to great lengths
            to ensure your satisfaction!
          </Text>
          <Button
            onClick={() => (window.location.href = "/contact")}
            w={60}
            p={6}
            bg={bgColor}
            colorScheme={"while"}
            mt={4}>
            <Text mr={2}>View Services</Text>
            <FaLongArrowAltRight />
          </Button>
        </Box>
      </CustomBanner>
      <ContentImage
        title="Official State Certified Star Smog Check Center!"
        description="Also looking for a Star Smog Check near you? We have been officially approved by the state to be a Star Certified. Not only do we provide Test Only services, be we also provide star tests. Some locations are only authorized to do Test Only. Often this means that the facilities has not met state standards, or have failed inspections by city officials."
        buttonText="Read More"
        imageUrl={M6}
        imageOnLeft={true}
      />
      <ContentImage
        title="We Specialize in RVs, Diesel Engine Vehicles, & Hybrids"
        description="Not many shops are equipped to handle large recreational vehicles, but we are. Don’t burn gas driving all over town with your RV only to get turned away from shop after shop. Bring your RV here. We have the equipment and space necessary for you to easily enter our work area and exit safely without much traffic through the street behind our sho"
        buttonText="Read More"
        imageUrl={M4}
        imageOnLeft={false}
      />
      <ContentImage
        title="Neighbor Hood Registration Services"
        firstText="Neighbor Hood Smog Shop"
        description="We offer all your DMV services without the big lines and wait times at the DMV. We are located (we’re your local smog certification center) CA on Magnolia Ave inside the Valero Gas Station. Our services include: Transfer of Ownership, Registration Renewal, One Day Moving Permit, Vehicle History Report, New Plates, Out of State Vehicle, Duplicate sticker, Duplicate Plate, Duplicate Registration, Duplicate Title, Apply or Remove Planned non Operation (PNO) and more! We service vehicles, boats, RVs, ATVs, motorcycles, and trailers."
        buttonText="Read More"
        imageUrl={M2}
        imageOnLeft={true}
      />
      <div style={{ position: "relative" }}>
        <CustomBanner title="Contact Us" imageUrl={M3} />
        <Flex
          direction={{ base: "column", md: "row" }}
          align={{ base: "center", md: "flex-start" }}
          justify={"center"}>
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0 }}
            transition={{ duration: 10 }}
            style={{ maxWidth: "600px", padding: 2, width: "100%" }}>
            <Box>
              <InfoBox info />
              <div style={{ width: "100%", height: "180px", margin: 10 }}>
                <iframe
                  title="Google Map"
                  srcDoc={`
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.1786906597904!2d-117.6916805!3d33.626608499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dce935a1b93131%3A0x87da44bc89cae463!2sAA%20Smog%20Test%20Only!5e0!3m2!1sen!2s!4v1704053296613!5m2!1sen!2s" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        `}
                  frameBorder="0"
                  width="100%"
                  height="100%"></iframe>
              </div>
            </Box>
          </motion.div>
          <Box
            maxW={{ base: "100%", md: "60%" }}
            mb={{ base: 8, md: 0 }}
            zIndex={1}>
            <ContactForm imageUrl={BannerH} />
          </Box>
        </Flex>
      </div>
      <HorizontalTab
        imageUrl={M4}
        leftContent={
          <Text fontWeight={"bold"} fontSize={20}>
            Let Us Service Your Vehicle Today!
            <br />
            Give us a call at: <strong>(909) 499-9590</strong> or visit our shop
            today.
          </Text>
        }
        rightContent={
          <Button
            onClick={() => (window.location.href = "/contact")}
            w={60}
            p={6}
            bg={"black"}
            colorScheme="white"
            _hover={bgColor}>
            <Text mr={2} fontStyle={"italic"}>
              Contact US
            </Text>
            <FaLongArrowAltRight />
          </Button>
        }
      />
    </>
  );
};

export default Home;
